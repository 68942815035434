::selection, ::-moz-selection {
  background: hsla(26, 81%, 52%, 1);
  color: white;
}

.jumbotron {
  background-color: white;
}

.arrows {
  position: relative;
  &:before {
    content: '';
    width: 30px;
    height: 30px;
    border-top: 5px solid hsla(26, 81%, 52%, 1);
    border-left: 5px solid hsla(26, 81%, 52%, 1);
    position: absolute;
    top: -5px;
    left: -5px;
  }
  &:after {
    content: '';
    width: 30px;
    height: 30px;
    border-right: 5px solid hsla(26, 81%, 52%, 1);
    border-bottom: 5px solid hsla(26, 81%, 52%, 1);
    position: absolute;
    bottom: -5px;
    right: -5px;
  }
}

.display-1 {
  font-size: 4rem;
}

.display-2 {
  font-size: 3.5rem;
}

.display-3 {
  font-size: 3.0rem;
}

.display-4 {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .display-1 {
    font-size: 6rem;
  }
  .display-2 {
    font-size: 5.5rem;
  }
  .display-3 {
    font-size: 4.5rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
}

.dropdown-item {
  &.active, &:active {
    background-color: #0063cc;
  }
  &:focus {
    background-color: #4da3ff;
  }
}

.navbar-light .navbar-nav {
  .active > .nav-link {
    color: #0063cc;
  }
  .nav-link {
    &.active {
      color: #0063cc;
    }
    color: #737373;
    /* Set darker text color for accessibility */
  }
}

blockquote > footer.blockquote-footer, figure > figcaption.figure-caption {
  color: #737373;
  /* Set darker text color for accessibility */
}

.header-link {
  color: #737373;
  left: -0.5em;
  padding-left: 5px;
  padding-bottom: 2px;
  opacity: 1;
}

h2:hover .header-link, h3:hover .header-link, h4:hover .header-link, h5:hover .header-link, h6:hover .header-link {
  opacity: 1;
}

pre {
  white-space: pre;
  overflow: auto;
  width: inherit;
  background-color: #fff;
}

.btn-primary, .btn .btn-primary .btn-file {
  background-image: none;
  background-color: #0063cc;
  border-color: #0063cc;
  color: white;
}

.btn-secondary {
  background-color: #737373;
  /* Set darker .btn-secondary background-color for accessibility */
}

.nav-pills {
  .nav-link.active, .show > .nav-link {
    background-color: #0063cc;
  }
}

.form-footer .cancel-button {
  background-color: hsla(0, 0%, 98%, 1);
}

#back-to-top {
  border-color: hsla(340, 62%, 32%, 1);
}

#back-to-top:focus {
  outline: 5px auto -webkit-focus-ring-color;
}

/* Insert the external-link icon if a link element contains target='_blank' */
.article-content a[target='_blank']::after {
  content: ' \f35d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
