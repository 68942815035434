.highlight {
  .hll {
    background-color: #ffffcc;
  }
  background: #fff;
  .c {
    color: #408080;
    font-style: italic;
  }
  .err {
    border: 1px solid #FF0000;
  }
  .k {
    color: #008000;
    font-weight: bold;
  }
  .o {
    color: #666666;
  }
  .ch, .cm {
    color: #408080;
    font-style: italic;
  }
  .cp {
    color: #BC7A00;
  }
  .cpf, .c1, .cs {
    color: #408080;
    font-style: italic;
  }
  .gd {
    color: #A00000;
  }
  .ge {
    font-style: italic;
  }
  .gr {
    color: #FF0000;
  }
  .gh {
    color: #000080;
    font-weight: bold;
  }
  .gi {
    color: #00A000;
  }
  .go {
    color: #888888;
  }
  .gp {
    color: #000080;
    font-weight: bold;
  }
  .gs {
    font-weight: bold;
  }
  .gu {
    color: #800080;
    font-weight: bold;
  }
  .gt {
    color: #0044DD;
  }
  .kc, .kd, .kn {
    color: #008000;
    font-weight: bold;
  }
  .kp {
    color: #008000;
  }
  .kr {
    color: #008000;
    font-weight: bold;
  }
  .kt {
    color: #B00040;
  }
  .m {
    color: #666666;
  }
  .s {
    color: #BA2121;
  }
  .na {
    color: #7D9029;
  }
  .nb {
    color: #008000;
  }
  .nc {
    color: #0000FF;
    font-weight: bold;
  }
  .no {
    color: #880000;
  }
  .nd {
    color: #AA22FF;
  }
  .ni {
    color: #999999;
    font-weight: bold;
  }
  .ne {
    color: #D2413A;
    font-weight: bold;
  }
  .nf {
    color: #0000FF;
  }
  .nl {
    color: #A0A000;
  }
  .nn {
    color: #0000FF;
    font-weight: bold;
  }
  .nt {
    color: #008000;
    font-weight: bold;
  }
  .nv {
    color: #19177C;
  }
  .ow {
    color: #AA22FF;
    font-weight: bold;
  }
  .w {
    color: #bbbbbb;
  }
  .mb, .mf, .mh, .mi, .mo {
    color: #666666;
  }
  .sa, .sb, .sc, .dl {
    color: #BA2121;
  }
  .sd {
    color: #BA2121;
    font-style: italic;
  }
  .s2 {
    color: #BA2121;
  }
  .se {
    color: #BB6622;
    font-weight: bold;
  }
  .sh {
    color: #BA2121;
  }
  .si {
    color: #BB6688;
    font-weight: bold;
  }
  .sx {
    color: #008000;
  }
  .sr {
    color: #BB6688;
  }
  .s1 {
    color: #BA2121;
  }
  .ss {
    color: #19177C;
  }
  .bp {
    color: #008000;
  }
  .fm {
    color: #0000FF;
  }
  .vc, .vg, .vi, .vm {
    color: #19177C;
  }
  .il {
    color: #666666;
  }
}

/* Comment */
/* Error */
/* Keyword */
/* Operator */
/* Comment.Hashbang */
/* Comment.Multiline */
/* Comment.Preproc */
/* Comment.PreprocFile */
/* Comment.Single */
/* Comment.Special */
/* Generic.Deleted */
/* Generic.Emph */
/* Generic.Error */
/* Generic.Heading */
/* Generic.Inserted */
/* Generic.Output */
/* Generic.Prompt */
/* Generic.Strong */
/* Generic.Subheading */
/* Generic.Traceback */
/* Keyword.Constant */
/* Keyword.Declaration */
/* Keyword.Namespace */
/* Keyword.Pseudo */
/* Keyword.Reserved */
/* Keyword.Type */
/* Literal.Number */
/* Literal.String */
/* Name.Attribute */
/* Name.Builtin */
/* Name.Class */
/* Name.Constant */
/* Name.Decorator */
/* Name.Entity */
/* Name.Exception */
/* Name.Function */
/* Name.Label */
/* Name.Namespace */
/* Name.Tag */
/* Name.Variable */
/* Operator.Word */
/* Text.Whitespace */
/* Literal.Number.Bin */
/* Literal.Number.Float */
/* Literal.Number.Hex */
/* Literal.Number.Integer */
/* Literal.Number.Oct */
/* Literal.String.Affix */
/* Literal.String.Backtick */
/* Literal.String.Char */
/* Literal.String.Delimiter */
/* Literal.String.Doc */
/* Literal.String.Double */
/* Literal.String.Escape */
/* Literal.String.Heredoc */
/* Literal.String.Interpol */
/* Literal.String.Other */
/* Literal.String.Regex */
/* Literal.String.Single */
/* Literal.String.Symbol */
/* Name.Builtin.Pseudo */
/* Name.Function.Magic */
/* Name.Variable.Class */
/* Name.Variable.Global */
/* Name.Variable.Instance */
/* Name.Variable.Magic */
/* Literal.Number.Integer.Long */