@media print {
  #header, #footer, #sidebar {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100vh;
  line-height: 1.7;
  font-family: Acherus, "Helvetica Neue", Helvetica, Sans-Serif;
  background-color: hsla(35, 13%, 98%, 1);
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: gineso-condensed, "Helvetica Neue", Helvetica, Sans-Serif;
}

.main-head {
  grid-area: header;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.main-nav {
  grid-area: main-nav;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.main-title {
  grid-area: main-title;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

.article-content {
  grid-area: main-body;
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}

.sidebar-right {
  grid-area: toc-nav;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
}

.main-footer {
  grid-area: footer;
  -ms-grid-row: 6;
  -ms-grid-column: 1;
}

.wrapper {
  min-height: 100%;
  display: -ms-grid;
  /* IE11 support */
  display: grid;
  grid-gap: 1em;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: auto;
  grid-auto-rows: minmax(0, auto);
  grid-auto-columns: minmax(0, 100vw);
  grid-template-areas: "header" "main-nav" "main-title" "toc-nav" "main-body" "footer";
}

@media (min-width: 992px) {
  .wrapper {
    -ms-grid-columns: 260px 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto minmax(0, min-content) minmax(0, min-content) auto;
    grid-template-columns: 260px 1fr;
    grid-template-areas: "header header" "main-nav main-title" "main-nav toc-nav" "main-nav main-body" "footer footer";
  }
  .main-head {
    -ms-grid-column-span: 2;
  }
  .main-nav {
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    -ms-grid-row-align: start;
  }
  .main-title {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .article-content {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
  }
  .sidebar-right {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }
  .main-footer {
    -ms-grid-column-span: 2;
  }
}

@media (min-width: 1300px) {
  .wrapper {
    grid-gap: 20px;
    -ms-grid-columns: 20% 1.5fr 1fr;
    -ms-grid-rows: auto 130px auto auto;
    grid-template-rows: auto minmax(0, min-content) min-content auto;
    grid-template-columns: 20% 1.5fr 1fr;
    grid-template-areas: "header header header" "main-nav main-title toc-nav" "main-nav main-body toc-nav" "footer footer footer";
  }
  .main-head {
    -ms-grid-column-span: 3;
  }
  .main-nav {
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-row-align: start;
  }
  .main-title {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .article-content {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-row-align: start;
  }
  .sidebar-right {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
  .main-footer {
    -ms-grid-column-span: 3;
  }
}

.main-head {
  border-top: 6px solid hsla(340, 62%, 32%, 1);
  background-color: white;
  .navbar {
    padding: 0 1rem;
  }
  .navbar-brand {
    padding: 0;
  }
  .logo {
    width: 210px;
    max-height: 100%; /* IE11 fix */
  }
}

@media (min-width: 992px) {
  .main-head .logo {
    width: 250px;
  }
}

.app-title {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 36px;
  font-size: 32px;
  font-weight: 500;
  font-family: gineso-condensed;
  font-variant: small-caps;
  letter-spacing: 1.8px;
  text-align: center;
}

@media (min-width: 576px) {
  .app-title {
    line-height: 40px;
    font-size: 36px;
    letter-spacing: 2px;
  }
}

body a {
  color: #0063cc;
  &:hover {
    color: hsla(208, 100%, 22%, 1);
  }
}

.article-content {
  max-width: 50em;
  word-wrap: break-word;
  a {
    text-decoration: underline;
    text-underline-position: under;
  }
  ul.nav-pills a {
    text-decoration: none;
  }
  table {
    display: block;
    overflow-y: auto;
  }
}

blockquote {
  border-left: 4px solid #E87722;
  padding: 0.5em 0.8em;
  background-color: #fae3d1;
  p {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
}

code {
  color: #cd186d;
}

.main-footer {
  background-color: hsla(35, 13%, 20%, 1);
  box-shadow: 0px 100vh 0px 100vh #3f3931;
  color: white;
  a {
    color: white;
    &:hover {
      color: white;
    }
  }
}

.chicago-maroon-bg {
  background-color: hsla(340, 62%, 32%, 1);
}

.burnt-orange-bg {
  background-color: hsla(26, 81%, 52%, 1);
}

.cadet-blue-bg {
  background-color: hsla(208, 100%, 22%, 1);
}

.hokie-stone-bg {
  background-color: hsla(210, 3%, 47%, 1);
}

.landgrant-grey-bg {
  background-color: hsla(35, 13%, 82%, 1);
}