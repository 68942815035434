.main-nav {
  a {
    color: inherit;
  }
  form {
    line-height: 2.4;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    display: block;
    line-height: 2;
    background-color: #fff;
    li ul li {
      color: hsla(210, 3%, 40%, 1);
      line-height: 1.4;
      padding-left: 1em;
    }
  }
}

.sidebar-grid {
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
}

.sidebar-flex-group {
  display: flex;
  justify-content: center;
}

.search-input {
  width: 100%;
  padding-left: 5px;
}

.main-nav {
  ul li {
    ul li {
      > a.nav-link {
        padding: 0.5rem 1rem;
      }
      .nav-text {
        padding: 0.5rem 1rem;
      }
    }
    > a:hover {
      text-decoration: underline;
    }
  }
  li.active > a {
    font-weight: bold;
    font-size: 1.1em;
    padding: .5rem 1rem;
    color: hsla(340, 62%, 32%, 1);
  }
  ul li ul li.child-active > a {
    font-weight: bold;
    color: hsla(340, 55%, 32%, 1);
  }
  .nav-text {
    padding: 0.5rem 1rem;
    display: block;
  }
  .nav-item {
    font-size: 18px;
  }
}

#sidebar-menu > ul {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.main-nav ul.results-container {
  list-style: none;
  font-size: 16px;
  background-color: white;
  position: absolute;
  top: 3.3em;
  left: 2em;
  z-index: 1;
  width: 85%;
  border-left: 1px solid #dedede;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

ul.results-container {
  li {
    padding: 0.5em;
    &.active-results {
      font-weight: bold;
    }
    &:not(:first-child) {
      border-top: 1px solid #dedede;
    }
  }
  a {
    background-color: transparent;
    &:hover {
      color: black;
    }
  }
}

.selected {
  font-weight: bolder;
}

@media (min-width: 992px) {
  .sticky-nav {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e6e6e6;
    padding-right: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 1rem;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .search-form {
    padding-bottom: 1em;
    border-bottom: 1px solid #e6e6e6;
  }
  #sidebar-menu {
    display: block !important;
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
    > ul {
      box-shadow: none;
    }
  }
  .main-nav {
    ul {
      background-color: inherit;
    }
    ul li {
      ul li {
        > a.nav-link {
          padding: 0.3rem 0.7rem;
        }
        .nav-text {
          padding: 0.3rem 0.7rem;
        }
      }
    }
    li.active > a {
      border-left: 4px solid hsla(340, 62%, 32%, 1);
      background-color: #fff;
    }
    ul {
      li ul li.child-active > a {
        border-left: 4px solid hsla(340, 62%, 32%, 1);
        background-color: #fff;
        padding: 0.8rem 1rem;
      }
      &.results-container {
        top: 2.8em;
        left: 1em;
      }
    }
    .nav-item {
      font-size: 1rem;
    }
  }
}