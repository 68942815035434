.sidebar-right {
  margin-bottom: 1em;
}

.toc, .toc-item {
  padding-left: 0;
  list-style: inherit;
  list-style-position: inside;
}

.toc ul {
  padding-left: 1em;
}

.toc-item > a {
  color: #736859;
  word-break: break-word;
}

@media (min-width: 1300px) {
  .toc-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 1rem;
    height: 100vh;
  }
  .toc {
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
  }
  .toc, .toc-item {
    list-style: none;
  }
  .toc li > a.active {
    font-weight: bold;
    border-left: 3px solid #736859;
    padding-left: 5px;
  }
}

/* Increase spacing & text size on mobile devices */
@media only screen and (max-device-width: 1299px) {
  .toc {
    line-height: 2.1;
    font-size: 18px;
  }
}